interface Props {
    children:string
}

//default body element for static template
export default function Body(props:Props) {

    return (
        <p>
           {props.children} 
        </p>
    )


}