import { Link } from "react-router-dom"

interface Props {
    route:string, 
    text:string
}

//default button for static template
export default function Button(props:Props) {
    return (
        <Link className="button" to = {props.route}>{props.text}</Link>
    )
}