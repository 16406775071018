import {XPBar, xp} from './Xp_bar'
import {useState, useEffect} from 'react';
import API from '../API'

// component that displays the xp bars for the current skills
function StatusReport() {

    //queries api for skills
    // and sets up component
    let [skills, setSkills] = useState<xp[]>([])
    let route:API = new API("get", "skills")

    useEffect(function() {
        (async function() {
            let data:any = await route.query()
            setSkills(data)
        })()
    }, [])

    // displays each skill
    return (
        <div>
            {skills.map(skill => (
                <XPBar key = {skill.name} {...skill} />
            ))}
        </div>
    );
}

export default StatusReport;