
interface Props {
    children:string, 
    color ?: string
}

//default header for static template
export default function Header(props:Props) {
    let color = "white"
    if (props.color) {
        color = props.color
    }
    return (
        <h2 className = "major" style = {{color:color}}>
            {props.children}
        </h2>
    )
}