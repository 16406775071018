import img404 from "./404.png"
import Image from "./Image"
import StaticTemplate from "./StaticTemplate";
import Header from "./Header";
import Button from "./Button";
import { useRouteError } from "react-router-dom";

//404 page
export default function Error404() {
    const error = useRouteError();

    return (
        <StaticTemplate title = "Not Found">
            <Button route= "/" text = "Return Home" />
            <Header>You Didn't See Anything Here</Header>
            <Image src = {img404} />
        </StaticTemplate>
    )
}