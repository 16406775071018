import "./achievements.css"
import API from "../API"
import lock from "./lock.png"
import { text } from "stream/consumers"

// achievement iterface that 
// models achievement object passed from api
export interface Achievement {
    name:string,
    image:string, 
    description:string,
    unlocked:boolean
}


// display for a single achievement
export function AchievementDisplay(props:Achievement) {
    let textColor = props.unlocked?"black":"white"
    let backgroundColor = props.unlocked?"white":"black"
    let imageStyle = props.unlocked?{border:"3px solid black"}:{filter:"grayscale(1)"}
    return (
        <article className="achievement" style={{padding:"1.5rem 2.5rem 1.5rem 2.5rem","borderRadius":"1rem",backgroundColor:backgroundColor}}>
            <div className = "achievement-imgs">
                {!props.unlocked &&
                    <img src={lock} className="locked-achievement"/>
                }
                <img src={API.get_image_url(props.image)} className="achievement-img" style={imageStyle} />
            </div>
            <div className = "achievement-text">
                <h3 className="major" style={{"borderBottom":"none",color:textColor}}> {props.name}</h3>
                <p style={{margin:"0px",color:textColor}}> {props.description} </p>
            </div>
        </article>
    )
}

