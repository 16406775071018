import API from '../API'
import {Task,EvidenceDisplay} from '../Log/TaskDisplay'

export interface Todo extends Task {
    priority: number, 
    etc: number,
    due_date:string | null,
    uuid:string
}

export default function TodoDisplay(props:Todo) {

    function format_date(date:string|null) {
        if (!date){
            return "N/A"
        }
        let d = date.split("-")
        if (d.length != 3) {
            return date
        }
        return `${d[1]}/${d[2]}/${d[0]}`
    }
    return (
        <>
        <article className="achievement" style={{padding:"1.5rem 2.5rem 1.5rem 2.5rem","borderRadius":"1rem"}}>
            <div className = "achievement-imgs">
                <img src={API.get_image_url(props.skill_image)} className="achievement-img" />
            </div>
            <div className = "achievement-text">
                <h3 className="major" style={{"borderBottom":"none"}}> {props.name}</h3>
                <div className = "flex-row">
                    <p>ETC: {props.etc}hr</p>
                    {props.due_date &&
                    <p>Due Date: {format_date(props.due_date)}</p>
                    }
                    <p>Priority: {props.priority}</p>
                </div>
            </div>
          
        </article>  
        </>
    )
}