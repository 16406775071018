import API from "../API"
import { useState } from "react"
import { LoadingAnimation } from "../Loading/Loading"

export default function DailyMeme(){
    let default_meme = "/api/daily_meme"
    let [meme, setMeme] = useState<string>(default_meme)
    let [loading,setLoading] = useState<boolean>(false)

    async function updateMeme() {
        setLoading(true)
        let api = new API("post","random_meme")
        let data:any = await api.query({},false)
        if (data == meme){
            setLoading(false)
        }
        setMeme(data)
    }
    return (
        <div>
            {
                loading &&
                <div className="flex-column">
                <LoadingAnimation />
                </div>
                
            }
        <div className="flex-column"style={loading?{display:"none"}:{}}>
            <h2 className="major" style={{textAlign:"center"}}>
                {default_meme == meme?
                "Daily Meme"
                :
                "Random Meme"
                }
            </h2>
            <img onLoad = {() => {setLoading(false)}} style = {{"width":"90%"}} src={API.get_image_url(meme)}></img>
            <p className = "calendar-button-edit" style = {{marginTop:"1rem"}}onClick={() => {updateMeme()}}>Get Random Meme</p>
            
        </div>
        </div>
        
    )

}