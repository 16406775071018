import React from 'react';
import logo from './logo.svg';
import './App.css';
import StatusReport from './Xp/Status_Report';
import StatusBubbles from './Xp/Status_Bubbles';
import DailyMeme from './Xp/Daily_Meme'
import { Link } from "react-router-dom";

// home page component
function Home() {
  return (
    <article style={{padding:".5rem 2.5rem 1.5rem 2.5rem"}}>
      <StatusReport />
      <DailyMeme />
      {/* <StatusBubbles /> */}
      {/* <Link to = "requirements" className = "home-link">What Does This Mean?</Link> */}
    </article>
  );
}

export default Home;
