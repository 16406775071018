import API from "../API"

// interface that represents
// a task passed by the api
export interface Task {
    id:number,
    name:string,
    skill:string,
    xp: number,
    timestamp:string,
    evidence:string,
    evidence_file:string,
    time_worked:number, 
    work_task:boolean, 
    skill_image:string,
    side_quest:boolean

}
export interface evidence {
    evidence_file:string,
    evidence:string
}

export function EvidenceDisplay(props:evidence) {
    if (props.evidence_file) {
        return (
            <a className = "evidence-file-url" href = {API.get_image_url(props.evidence_file)}>{props.evidence}</a>
        )
    } else if(props.evidence.startsWith("http")) {
        return (
            <a className = "evidence-file-url" href = {props.evidence}>{props.evidence}</a>
        )
        
    } else if(props.evidence) {
        return (
            <p>{props.evidence}</p>
        )
    } else {
        return (
            <></>
        )
    }
}
// react component that displays
// a given task
export function TaskDisplay(props:Task) {


    // parses the timestamp passed in from the api
    // and makes it user friendly
    function parseDate(time:string) {
        let date = new Date(Date.parse(time))
        return `${date.getHours()}:${("0" +date.getMinutes()).slice(-2)}`
    }
    
    return (
        <div className = "flex-column task" style={{width:"100%"}}>
            <h3 className = "major" style={{"maxWidth":"100%","textAlign":"center"}}>
                {props.name}{!props.work_task && "*"}
            </h3>
            <div className = "flex-row">
                <p>
                    Xp Earned:{props.xp}
                </p>
                <p>
                    Skill: {props.skill}
                </p>
            </div>
            <div className = "flex-row">
                <p>
                    Time Completed: {parseDate(props.timestamp)}
                </p>
                <p>
                    Time Worked: {props.time_worked}
                </p>
            </div>
            {(props.evidence || props.evidence_file) &&
                <h4 className = "evidence-header">Additional Info</h4>
            }
            <EvidenceDisplay evidence={props.evidence} evidence_file={props.evidence_file}/>
        </div>

    )
}