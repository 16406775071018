
interface Props {
    name:string, 
    options:any[],
    selected:any,
    update:any,
}

// drop down component
// used to select various options
export default function DropDown(props:Props) {

   let selected = props.selected
    if (!selected) {
        selected = props.options[0] 
    }

    return (
        <div className="achievement-selector">
            <h2 style={{letterSpacing:"0px",margin:0,padding:"0 1rem 0 0",textAlign:"center"}}>{props.name}</h2>
            <div>
            <select
                value = {selected}
                onChange = {e => props.update(e.target.value)}
                >
                {props.options.map(opt => (
                    <option key = {opt} value={opt}>{opt}</option>
                ))}
            </select>
        </div>
        </div>
        
    )  
}