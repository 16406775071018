import TodoDisplay from './TodoDisplay'
import {Todo} from './TodoDisplay'
import {useState,useEffect} from 'react'
import API from '../API'
import {Stack} from './Stack'
import StackDisplay from './Stack'
import DropDown from '../Achievements/Dropdown'

export default function PriorityQueue() {
    const [stacks, setStacks] = useState<any>(null)
    const [selectedStack, setSelected] = useState<string>("")

    useEffect(function() {
        (async function(){
            let api = new API("get","priority_queue")
            let data:any = await api.query() 
            
            let stacks:any = {}
            for(let i = 0; i < data.length;i++) {
                stacks[data[i].name] = data[i]
            }
            setStacks(stacks)
            setSelected(Object.keys(stacks)[0])
        })()
    },[]) 

    // only load if api has been queried
    if (stacks == null) {
        return (
            <></>
        )
    }
    return (
        <div className="achievements-container">
            <DropDown name="Select Task Stack:" options = {Object.keys(stacks)} 
            selected = {selectedStack}
            update = {(value:any) => setSelected(value)} />
            <StackDisplay {...stacks[selectedStack]} />
        </div>
    )
}