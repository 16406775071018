import {Todo} from "./TodoDisplay"
import TodoDisplay from "./TodoDisplay"

export interface Stack {
    name:string, 
    todos: Todo[]
}



export default function StackDisplay(props:Stack) {

    let tasks = props.todos
    return (
        <div className="achievements-container">
        {tasks.length == 0 && 
        <h3 id ="main" style={{color:"lime","textAlign":"center"}}>
            All Tasks Complete
        </h3>
        }
        <div className="achievements-container">
            {tasks.map((task) => (<TodoDisplay key = {task.uuid} {...task} />))}
        </div>
    </div>
    )
}