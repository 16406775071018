import React from "react";
import "./xp.css"
import ProgressBar from "@ramonak/react-progress-bar";

// interface for each skill 
// that models the way it is passed from the api
export interface  xp {
    name:string,
    color:string,
    next_level_xp:number,
    current_level_xp:number, 
    current_xp:number
    current_level: number,
    max_level: number,
    uuid:string
}

// generates an xp for each skill
// uses progress bar react component I found online

export function XPBar(props:xp) {
    let level_difference = props.next_level_xp - props.current_level_xp
    let progress = props.current_xp - props.current_level_xp
    return (
        <div style={{margin:"2rem 0 0 0"}}>
            <h3 className="major" style={{"margin":"0 0 1rem 0"}}>{`${props.name} level: ${props.current_level}`}</h3>
            {props.current_level < props.max_level &&
            <label>Xp to next level: {props.next_level_xp - props.current_xp}</label>
            }
            
            <div className="xp-bar-box">
            <h3>{props.current_level}</h3>
            {/* 
            // @ts-ignore */}
            {props.current_level < props.max_level?
                <>
                <ProgressBar completed={progress} customLabelStyles={{"fontFamily": `"Courier New", monospace`,color:"#e0e0e0"}}
                bgColor = {props.color}
                className = "xp-bar"
                baseBgColor="gray"
                customLabel= {`${Math.floor((progress/level_difference)* 100)}%`}
                maxCompleted={level_difference}/>
                <h3>{props.current_level + 1}</h3>
                </>
                :
                <>
                <ProgressBar completed={1} customLabelStyles={{"fontFamily": `"Courier New", monospace`,color:"#e0e0e0"}}
                bgColor = {props.color}
                className = "xp-bar"
                baseBgColor="gray"
                customLabel= {"Max Level"}
                maxCompleted={1}/>
                <h3>Max</h3>
                </>
            }
            
            </div>
        </div>
    );
}

