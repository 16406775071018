import API from "../API"
import {Achievement,AchievementDisplay} from "./Achievement"
import { useEffect,useState } from "react"
import DropDown from "./Dropdown"
import { xp
 } from "../Xp/Xp_bar"


function sortAchievements(achi:Achievement[]) {
    //shuffle achievements in random order
    for (let i = 0; i < achi.length * 20; i++) {
        let first = Math.floor(Math.random() * achi.length)
        let second = Math.floor(Math.random() * achi.length)
        let temp = achi[first]
        achi[first] = achi[second]
        achi[second] = temp
    }
    //put unlocked achievements first
    for (let i = 0; i < achi.length; i++) {
        if(achi[i].unlocked) {
            achi.splice(0,0, achi[i])
            achi.splice(i + 1,1)
        }
    }
}
function Achievements() {

    const [achievements, setAchievements] = useState<Achievement[]>([] as Achievement[])
    const [skills, setSkills] = useState<xp[]>([] as xp[])
    const [skill, setSkill] = useState<xp | null>(null)

    //queries api for all
    //skills that have achievments
    useEffect(
        function(){
            (async function(){
                let api = new API("get","skills")
                let ret:any = await api.query({achievements:true})
                setSkills(ret)
                if (ret.length > 0) {
                    setSkill(ret[0].name)
                }
            })()
        }, [])

    //queries api for all achievements of a specific skill
    useEffect(
        function() {
            (async function(){
                if (skill != null) {
                    let api = new API("get","achievements")
                    let ret:any = await api.query({"skill":skill})
                    setAchievements(ret)
                }
            })()
        },[skill])


    
    //shuffles the achievement order 
    let achi = [...achievements]
    //shuffles the achievement order & puts unlocked 
    //achievements first
    sortAchievements(achi)
    // creates a display for all 
    // achievements with the ability 
    // to change the skill to display achievements for
    return (
        <div className="achievements-container" style = {{marginTop:"2rem"}}>
        <DropDown name = "Achievements For:" options={skills.map((skill) => (skill.name))} selected={skill?skill:""} update={(s:any) =>{setSkill(s)}}/>
        <p style = {{margin:0,padding:"1rem 0 0 0"}}>
            {achievements.reduce((acc, curr) => ((curr.unlocked?1:0) + acc), 0)}/{achievements.length} unlocked
        </p>
        <div className="achievements-container">
            {achi.map((ach) => (<AchievementDisplay key = {ach.name} {...ach} />))}
        </div>
        </div>
    )
}

export default Achievements