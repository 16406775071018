import React,{useState} from 'react';
import { Outlet, Link, useLocation, useOutlet } from "react-router-dom";
import API from './API';
import Loading from './Loading/Loading';

export const APIError = React.createContext(null);

//default background for each webpage
function Background(props:any) {

    //gets route 
    //used to determine highlighted nav-item on the navbar
    const location = useLocation();

    // kind of cheese
    // allows api to interrupt the current page
    // to set a loading screen or display the error page
    // see API.tsx for more information
    let [error, setError] = useState(false);
    let [loading, setLoading] = useState(false);
    API.setError = setError;
    API.setLoading = setLoading;


    //style for selected link
    function style(str:string) {
        if (str == location.pathname) {
            return "selected-link"
        }
    }

    //default error page
    if (error) {
        return (
            <div id ="wrapper">
            <h3 id ="main" style={{color:"red","textAlign":"center"}}>
                An API Error Has Occured Please Check Back Later
            </h3>
            </div>
        )
    }

return (
    //@ts-ignore
    <APIError.Provider value={{loading:loading, setLoading:setLoading}}>
    <div id = "wrapper">
    <header id="header" style = {{margin:"0 0rem 2rem 0"}}>
        <nav className = "nav-bar">
            <ul className= "link-container" style = {{"flexDirection":"row"} as React.CSSProperties}>
                <li className = {style("/")}><Link to="/" >Home</Link></li>
                <li className = {style("/log")}><Link to="/log">Log</Link></li>
                <li className = {style("/priority_queue")} ><Link to = "/priority_queue">Quests</Link></li>
            
            </ul>
            <ul className = "link-container" >
                
                <li className = {style("/achievements")}><Link to ="/achievements">Achievements</Link></li>
            </ul>
        </nav>
        {/* <div className="content">
            <div className="inner">
                <h1>Dimension</h1>
                <p>A fully responsive site template designed by <a href="https://html5up.net">HTML5 UP</a> and released<br />
                for free under the <a href="https://html5up.net/license">Creative Commons</a> license.</p>
            </div>
        </div> */}
    </header>
    <div id="main">
        <Loading>
            <Outlet />
        </Loading>
    </div>
    <footer id="footer">
    <p className="copyright">A Heise Prduction <a href="https://ryanfheise.com">Personal Page</a></p>
    </footer>
    </div>

    </APIError.Provider>
    )
}

export default Background;