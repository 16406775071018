import { useState } from "react";
import down from "./down.png"
import { TaskDisplay, Task } from "./TaskDisplay";


// Day interface that models the 
// day object that is passed by the api
export interface Day {
    date:string|null
    time_worked:number,
    satisfactory:string,
    explanation:string,
    tasks:Task[],
    day:number
}

//formats the raw date from the date field
// into one that looks nice
export function formatDate(time:string, add:number=0) {
    const months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
        ];
    let date = new Date(Date.parse(time))
    date.setDate(date.getDate() + add)
    let month = months[date.getMonth()]
    let day:string | number = date.getDate();
    if (day % 10 == 1 && day != 11){
        day = "st"
    } else if (day % 10 == 2 && day != 12) {
        day = "nd"
    } else if (day % 10 == 3 && day !=13) {
        day = "rd"
    } else {
        day = "th"
    }
    return `${month} ${date.getDate()}${day} ${date.getFullYear()}`
}

export function DayDisplay(props:Day) {
//creates a display for a given day

    // the show field
    // determines whether or not a day
    // should display all the information
    // or just the current date
    let [show, setShow] = useState<boolean>(false)

    let color = null;
    switch(props.satisfactory) {
        case("Complete"):
            color = "green"
            break 
        case("Incomplete"):
            color = "red"
            break
        case("In Progress"):
        case("Neutral"):
            color = "gray"
            break
        case("Wash"):
        default:
            color = "black"
            break
    }
    // count number of non-productive tasks
    let num_non_prod = props.tasks.reduce((prev, task) => {return (prev + (task.work_task?0:1))}, 0)
    let side_quests =  props.tasks.filter((x) => x.side_quest)
    let main_quests = props.tasks.filter((x) => !x.side_quest)
    return (
        <div id = "log-root">
        <article className = "day" >
            <div className = "drop-down" onClick={() => {setShow(!show)}}>
                <h2 className="major date" style={{border:"0px solid black",color:color}}>
                    {/* {formatDate(props.date, 1)}
                     */}
                     {`Day ${props.day}`}
                </h2>
                <img src={down} id="arrow" style={show?{transform:"rotateX(180deg)"}:{}}/>
            </div>
            
        
        </article>
        {show &&
            <div className = "task-container flex-column">
                <p>{props.explanation}</p>
                <div className = "flex-row">
                    <p>
                        Hours Worked: {`${props.time_worked} hour${(props.time_worked != 1)?"s":""}`}
                    </p>
                    {props.date &&
                    <p>
                        Date Completed: {formatDate(props.date)}
                     </p>
                    }
                    
                    <p style ={{color:color}}>
                        Status: {props.satisfactory}
                    </p>
                </div>
                { main_quests.length > 0 &&
                <h2>Main Quests</h2>
                }   
                {main_quests.map((task) => (<TaskDisplay {...task} key = {task.id}/>))}
                {side_quests.length > 0 &&
                <h2>Side Quests</h2>
                }
                {side_quests.map((task) => (<TaskDisplay {...task} key = {task.id}/>))}
                {
                    num_non_prod > 0  &&
                    <p>* denotes tasks that do not contribute to the total productive time</p>
                }
            </div>
            }

        </div>
    )

}