import StaticTemplate from "./StaticTemplate";
import Header from "./Header";
import Body from "./Body";
import {List,Item} from "./List";
import SubHeader from "./SubHeader";


// The static page representing the explation for the
// status bubbles
// uses the static template to format
export default function Requirements() {

    return (
        <StaticTemplate title = "Status Bubbles">

            <Body>
            The status bubbles on the home page represent the status of each of the last 28 days. Here are what the different colors represent:
            </Body>
            <List>
                <Item color = "green">
                    Green bubbles are satisfactory days
                </Item>
                <Item color = "gray">
                    Gray bubbles are neutral days
                </Item>
                <Item color = "red">
                    Red bubbles are incomplete days
                </Item>
                <Item>
                    All meals must be healthy
                </Item>
            </List>

            <Body>
                This document will detail all of the requirements for the various status bubbles.
                It will also explain certain exceptions to these rules for special circumstances.
            </Body>

            <Header color ="green">Satisfactory Requirements</Header>
            <Body>The requirements for a satisfactory day are as follows:</Body>
            <List>
                <Item>
                    Weekly Productivity Requirement (See General Achievements)
                </Item>
                <Item>
                    60 minutes of physical activity
                </Item>
                <Item>
                    At least one meal must be healthy
                </Item>
            </List>
            <SubHeader color = "green">
                Satisfactory Week
            </SubHeader>
            <Body>For the purposes of achievements a week will be considered satisfactory if there are at least 6 satisfactory days and 1 neutral day or satisfactory day.</Body>
            <Header color = "gray">
                Neutral Requirements
            </Header>
            <Body>The requirements for a neutral day are as follows:</Body>
            <List>
                <Item>Three Quarters of the Weekly Productivity Requirement</Item>
                <Item>30 minutes of physical activity</Item>
            </List>
            <SubHeader>
                Weekly Day Off
            </SubHeader>
            <Body>
                Every week I will award myself with one day off. This day will always be marked as a wash. I can accumulate these days if I decide I want a few consecutive days off. Each day off must be selected beforehand so that I do not use it as an excuse 
                for not completing work on a day I am supposed to be productive.
            </Body>

            <Header color = "red">
                Incomplete Day
            </Header>
            <Body>
                A day will be marked as incomplete if it does not meet the requirments for a satisfactory or neutral day.
            </Body>
            <Header>
                Exemptions
            </Header>
            <SubHeader>
                Constrained Day
            </SubHeader>
            <Body>
                A day will be considered constrained if there is significant travelling, it is a holiday, or something else that comes up. These are days where I can not be fully productive and therefore will have easier requirements to be satisfactory or neutral. 
                The satisfactory requirements on such a day will be the same as on a standard neutral day. The neutral requirements on a constrained day will just be to obtain 30 minutes of exercise and 2.5 hours of work. Examples of a contrained day are days that include 
                travelling, a small event, etc...
            </Body>

            <Header>Wash</Header>
            <Body>
                The following Holidays will be considered a wash and exempt from logging:
            </Body>
            <List>
                <Item>Thanksgiving</Item>
                <Item>Christmas</Item>
                <Item>New Years Eve</Item>
                <Item>New Years Day</Item>
                <Item>Spring Break</Item>
                <Item>My Birthday</Item>
            </List>
            <Header>
                Clarifications
            </Header>
            <SubHeader>
                Major vs Minor Skills
            </SubHeader>
            <Body>
                For Major skills the maximum level is 50 and for minor skills the maximum level is 20. 
                Major skills are skills that I wish to develop over a long period of time (6 months+). 
                Minor skills are skills I wish to accomplish over a shorter period of time ( less than 2 months).
                The formula used to calculate a skill level is: (max_level * (current_xp/estimated_max_xp)^(.57))
            </Body>
            <Header>
                28 Day Goals
            </Header>
            <Body>
                My goal is to be able to work 10 hours a day 6 days a week. I will be slowly working my way up to the goal by setting smaller monthly goals and working my way up. 
                Each time I do not finish the monthly goal I will have to donate $100 to charity. However, I can not simply spend that money from my bank account. 
                Instead I must sell something that I enjoy and use that money to pay the $100 dollars. I believe that this will be a much greater incentive to complete my monthly goals.
            </Body>
            <SubHeader>
                August 21st-September 18th
            </SubHeader>
            <Body>
                I must have 3 or fewer incomplete days throughout the 28 day period.
            </Body>
            <Header>
                Additional Notes
            </Header>
            <SubHeader>
                August 27th, 2023
            </SubHeader>
            <Body>
                I had a bit of a rough start to the semester.
                I think this is due to making some poor decisions that seemed like they had a small impact but quickly added up.
                Throughout the week I slowly moved my bedtime further and further back so I slept through one of my classes on Wednesday. 
                I also didn't keep up with doing the dishes or laundry and this lead to an overwhelming amount of work on Sunday. 
                I spent an egregious amount of time playing rdr2. 
                I have decided to implement the following changes:
            </Body>
            <List>
                <Item>
                    Incentivized completing chores
                </Item>
                <Item>
                    Incentivized completing morning & nightly routines (going to bed on time)
                </Item>
                <Item>Added weekly xp bonuses for not overindulging in video games</Item>
                <Item>I added this incentives as part of the health skill</Item>
            </List>
            <SubHeader>
                December 31st, 2023
            </SubHeader>
            <Body>
                Rip that semester was rough ngl. I'm taking the next five months of to grind out some things and get my shit together.
                In order to accomplish this I'm going to try slowly building up to a the work ethic I want to achieve. I will start by slowly uping the amount of 
                time I'm working per week as monitored under the general achievements tab. I've also noticed that a lot of the problems I'm currently facing are time management related 
                and symptoms of ADHD so perhaps I'll get myself tested eventually. 
            </Body>
            <SubHeader>
                January 28th, 2024
            </SubHeader>
            <Body>
                I took a month off instead of just a week or so. Part of me feels as though I just needed to take time off and just take a step back. Another part of 
                me believes that I just wasted the past few weeks and this will probably hurt me in the long run. Whether or not the break was worthwhile I think only time will tell. 
                Starting this week I'm going to start gettting back into the swing of things. I've also realized how crucial it is for my success that I get my sleep in order. 
                Therfore, I've added a sleep skill that will help me track how much quality sleep I'm getting. 
                In order for a night to be considered a "good night sleep" I must obtain at least 7 and a half hours of sleep 
                as well as going to bed between 10pm-12am and waking up between 6am-7:30am. I've also removed wash days from appearing as part of the past 28 day status bubbles. This way it will only show days 
                that I am supposed to be working. You can still see the summary for that day under the log tab.
            </Body>
            <SubHeader>
                Febuary 2nd, 2024
            </SubHeader>
            <Body>
                I've come to realize how crucial it is for me to develop a routine. Therefore, I am modifying the sleep category to help me track how well I am following a daily routine. Xp will be awarded in that category for accomplishing the following:
            </Body>
            <List>
                <Item>Awake between 6:00-7:30am</Item>
                <Item>Start being productive before 8am</Item>
                <Item>Head to the gym for 1-1.5hrs after finishing work</Item>
                <Item>In bed before 11pm</Item>
                <Item>Sticking to my diet</Item>
            </List>
            <Body>
                This document was last edited on Febuary 2nd, 2024
            </Body>
        </StaticTemplate>
    )



}