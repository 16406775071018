interface ListProps {
    children:any
}

// default list for static template
export function List(props:ListProps) {
    return (
        <ul>
            {props.children}
        </ul>
    )
}

interface ItemProps {
    children:string,
    color ?: string
}

//default list item for static template
export function Item(props:ItemProps) {
    let color = "white"
    if (props.color) {
        color = props.color
    }
    return (
        <li style = {{color:color}}>{props.children}</li>
    )
}