

interface Request {
    status: boolean,
    data: any
}

// api class that controls
// how data is recieved from the api
export default class API {

    // determines current url to use for the api
    static api_url = "https://api.habits.heise.ai"
    // static api_url = "http://127.0.0.1:8000"

    // route and method for the query
    route:string;
    method:string;

    //a little bit of cheese that
    // allows the api to interrupt 
    // the current page for the loading animation
    static setError:any = null;
    static setLoading:any = null;

    constructor(method:string,route:string) {
        this.route = route;
        this.method = method;
    }
    
    async query(data:any={}, load=true):Promise<Request> {
        
        let req = null;
        //sets loading animation
        if (load) {
            //should only load if caller wants it too
            API.setLoading(true);
        }
        

        //tries to query api with data
        try{
            if (this.method == "get") {
                req = await this.query_get(data);
            } else {
                req = await this.query_post(data);
            }
            if (req.status != 200) {
                API.setError(true)
                API.setLoading(false);
                return {status:false, data:"An error occured"};
            }
        } catch {
            //if there is an error
            //it sets the error page
            API.setError(true)
            API.setLoading(false);
            return {status:false, data:"An error occured"};
        }
        // otherwise the data was recieved
        // just find so it will send it back
        // to the page that called it and turn off loading
        API.setLoading(false);
        return await req.json();
    }

    async query_post(data:any):Promise<Response> {

        // queries a post request

        let request_data = new FormData();
        let keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            request_data.append(keys[i],data[keys[i]]);
        }
        return await fetch(this.generateRoute(), {body:request_data,method:this.method})
    }

    async query_get(data:any):Promise<Response> {

        //queries a get request
        let keys = Object.keys(data);
        let args = "";
        for (let i = 0; i < keys.length; i++) {
            if (i == 0){
                args += "?"
            }
            args += keys[i]
            args += "="
            args += data[keys[i]]
            if (i != keys.length - 1) {
                args += "&"
            }
        }
        return await fetch(this.generateRoute() + args, {method:this.method})
    }

    generateRoute():string {

        //generates api string to query for fetch
        return API.api_url + "/api/" + this.route 
    }

    static get_image_url(url:string):string {
        
        //gets a url for the image returned from the api
        return API.api_url + url;
    }
};