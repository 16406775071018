import "./static.css"

interface Props {
    children:any,
    title:string
}

// default template for a static page 
// i.e. an error page or blog page
export default function StaticTemplate(props:Props) {

    return (
        <article className = "flex-column" id = "static_header">
            <h1 className = "major">
                {props.title}
            </h1>
            {props.children}
        </article>
    )




}